import { createStore } from 'vuex';

import { store as lang, LangStore, State as LangState } from '@/store/modules/Lang/';
import { store as countries, CountryStore, State as CountryState } from '@/store/modules/Countries/';
import { store as personals, PersonalStore, State as PersonalState } from '@/store/modules/Personals/';
import { store as quotes, QuoteStore, State as QuoteState } from '@/store/modules/Quotes/';

export type RootState = {
  countries: CountryState;
  lang: LangState;
  personals: PersonalState;
  quotes:QuoteState;
};

export type Store = LangStore<Pick<RootState, 'lang'>>
& CountryStore<Pick<RootState, 'countries'>>
& PersonalStore<Pick<RootState, 'personals'>>
& QuoteStore<Pick<RootState, 'quotes'>>;

export const store = createStore({
  modules: {
    countries,
    lang,
    quotes,
    personals
  }
});

export function useStore(): Store {
  return store as Store;
}
