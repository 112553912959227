<template>
    <header class="bg-white vechile-detail">
            <div class="container custom-container">
                <nav class="navbar navbar-expand-lg navbar-light p-0">
                    <a class="navbar-brand p-0" :href="homePage" v-if="language === 'ar'">
                        <img src="@/assets/images/arabic-logo.jpg" class="img-fluid d-none d-lg-inline" width="280">
                        <img src="@/assets/images/arabic-logo.jpg" class="img-fluid d-lg-none" width="229">
                    </a>
                    <a class="navbar-brand p-0" :href="homePage" v-else>
                        <img src="@/assets/images/BAI_logo.svg" class="img-fluid d-none d-lg-inline" width="280">
                        <img src="@/assets/images/mobile-logo.svg" class="img-fluid d-lg-none" width="229">
                    </a>
                    <!-- where button header -->
                    <h-header-default />

                    <div class="header-right d-flex flex-wrap align-items-center header-right-custom">
                        <div class="header-right__contact d-flex flex-wrap align-items-center">
                            <a href="https://api.whatsapp.com/send?phone=96171396654" class="header-right__contact__item position-relative whats-app" target="_blank">
                                <div class="header-icon">
                                    <img src="@/assets/images/icons/whatsapp.svg" class="img-fluid">
                                </div>
                            </a>

                            <a href="tel:01488277" class="header-right__contact__item position-relative call-app">
                                <div class="header-icon">
                                    <img src="@/assets/images/icons/call.svg" class="img-fluid">
                                </div>
                                <div class="header-number" style="direction:ltr;">
                                    <p>01 488 277</p>
                                </div>
                            </a>
                        </div>


                    </div>

                </nav>
            </div>
        </header>
</template>
<script>
import { computed, defineComponent } from 'vue';

import { store } from '@/store';
import router from '@/router';
import Language from '@/core/services/Language';

export default defineComponent({
    created() {
        const html = document.documentElement // returns the html tag
        html.setAttribute('lang', window.localStorage.getItem('language'))
        html.setAttribute('dir', window.localStorage.getItem('language') == 'en' ? 'ltr' : 'rtl')
    },
    
    setup() {
        // const current = computed(() => {
        //     return store.state.steppers.stepper
        // })

        const go = (val, y) => {
            // let uuid = window.localStorage.getItem(uid(val));
            // // console.log(uuid)
            
            // if(y) {
            //     if(uuid) {
            //         router.push({name: val, query: {uuid: uuid}});
            //     } else {
            //         router.push({name: val});
            //     }
            // }
            console.log('go')
        }


        const language = computed(() => {
            return store.state.lang.lang
        })

        const changeLanguage = (e) => {
            Language.setLanguage(e);
            window.location.reload();
        }

        const homePage = process.env.VUE_APP_PAYMENT_GATEWAY_URL + `/` + language.value;

        return {
            homePage,
            language,
            go,
            changeLanguage
        }
    }    
})

</script>