import { ActionTree, ActionContext } from 'vuex';
import ApiService from '@/core/services/ApiService';

// eslint-disable-next-line import/no-cycle
import { RootState } from '@/store';

import { State } from './state';
import { Mutations } from './mutations';
import { PersonalMutationTypes } from '@/core/enum/Personals/Mutations';
import { PersonalActionTypes } from '@/core/enum/Personals/Actions';
import { Links } from '@/core/enum/Links';
import axios from 'axios';
import { Health } from '@/core/types';
import router from '@/router';

type AugmentedActionContext = {
  commit<K extends keyof Mutations>(
    key: K,
    payload: Parameters<Mutations[K]>[1],
  ): ReturnType<Mutations[K]>;
} & Omit<ActionContext<State, RootState>, 'commit'>

export interface Actions {
  [PersonalActionTypes.POST_PERSONAL_DETAILS](
    { commit }: AugmentedActionContext,
    payload: Health
  ): any;
  [PersonalActionTypes.GET_CUSTOMER](
    { commit }: AugmentedActionContext,
    payload: Health
  ): any;
}

export const actions: ActionTree<State, RootState> & Actions = {
  async [PersonalActionTypes.POST_PERSONAL_DETAILS]({ commit }, payload) {
    commit(PersonalMutationTypes.SET_CUSTOMER_LOADING, true);
    try {
      const data = await axios.post(Links.POST_PERSONAL_DETAILS, payload);
      const personal_details = data.data.data.customer;
      
      window.localStorage.setItem('lead_id', data.data.data.lead_id)
      if(data.data.data.uuid  != window.localStorage.getItem('uuid')) {
        window.localStorage.setItem('uuid', data.data.data.uuid)
      } 
      commit(PersonalMutationTypes.SET_CUSTOMER_RESULT, personal_details);
      commit(PersonalMutationTypes.SET_CUSTOMER_LOADING, false);      
      window.localStorage.setItem('personal_details', JSON.stringify(personal_details));
      window.localStorage.setItem('personal_detail_submitted', JSON.stringify(payload));
      // const route = router.resolve({ name: 'ThankYou' });
      const route = router.resolve({ name: 'ViewQuotes' });
      window.location.href = route.href;
    }
    catch (err) {
      return err;
    }
  },
  async [PersonalActionTypes.GET_CUSTOMER]({ commit }, payload) {
    try {
      const data = await axios.post(Links.GET_CUSTOMER, payload);
      const personal_details = data.data.data.customer;
      
      const filtereCustomer = customer(personal_details, payload);
      commit(PersonalMutationTypes.SET_CUSTOMER, filtereCustomer);
      
      commit(PersonalMutationTypes.SET_CUSTOMER_RESULT, personal_details);
      commit(PersonalMutationTypes.SET_CUSTOMER_LOADING, false);      
      window.localStorage.setItem('personal_details', JSON.stringify(personal_details));
      return true;
    }
    catch (err) {
      return true;
      return err;
    }
  },
};


function customer(data, payload) {
  let customerD  = data
  const uuid = window.localStorage.getItem('uuid');
  const pet = {
    id: customerD.id,
    lang: payload.lang,
    customer_name: customerD.customer_name,
    customer_email: customerD.customer_email ?? null,
    customer_phone: customerD.customer_phone,
    phone_country_code: customerD.phone_country_code ?? '+961',
    insurance_type: customerD.insurance_type ?? 6,
    customer_source: customerD.customer_source ?? null,
    utm_source: customerD.utm_source ?? null,
    utm_medium: customerD.utm_medium ?? null,
    utm_campaign: customerD.utm_campaign ?? null,
    utm_content: customerD.utm_content ?? null,
    uuid: uuid,
    leads: {
      id: customerD.leads?.id ?? 0,
      customer_id: customerD.id ?? 0,
      pet_name: customerD.leads?.pet_name != 'QL' ? customerD.leads?.pet_name : null,
      pet_type: customerD.leads?.pet_name != 'QL' ? customerD.leads?.pet_type : null,
      pet_gender: customerD.leads?.pet_name != 'QL' ? customerD.leads?.pet_gender : null,
      pet_age: customerD.leads?.pet_age ?? null,
      insurance_policy: customerD.leads?.insurance_policy ?? 0,
      lead_source: customerD.leads?.lead_source ?? null,
      duplicate_source: customerD.leads?.duplicate_source ?? null,
      other_source: customerD.leads?.other_source ?? null,
      utm_source: customerD.leads?.utm_source ?? null,
      utm_medium: customerD.leads?.utm_medium ?? null,
      utm_campaign: customerD.leads?.utm_campaign ?? null,
      utm_content: customerD.leads?.utm_content ?? null,
      other_utm_source: customerD.leads?.other_utm_source ?? null,
      other_utm_medium: customerD.leads?.other_utm_medium ?? null,
      other_utm_campaign: customerD.leads?.other_utm_campaign ?? null,
      other_utm_content: customerD.leads?.other_utm_content ?? null,
      is_editable: true,
    }
  }
  return pet;
}