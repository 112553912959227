<template>
  <div class="form-block">
    <div class="label-block">
      <div class="label-block_label">
        <label>{{ label }}<span v-if="isRequired"> *</span> </label>
      </div>
      <div class="label-block_question position-relative" v-if="isTooltip">
        <el-tooltip class="box-item" effect="light">
          <template #content>
            <p class="tooltip-content">{{ tooltipMessage }}</p>
          </template>
          <a
            class="popper"
            data-bs-trigger="hover"
            placement="right"
            data-bs-toggle="popover"
          >
            <div class="icon">
              <span class="icon-question-icon"></span>
            </div>
          </a>
        </el-tooltip>
      </div>
    </div>

    <template v-if="isMobile">
      <Teleport v-if="age_id" :to="`#${age_id}`">
          <el-input v-model="search" placeholder="Search .." ref="age_ref" style="position: absolute; top: 0px" />
      </Teleport>
    </template>

    <div class="input-block">
      <div class="row">
        <div class="col-lg-10">
          <el-form-item :prop="formProp">
            <el-select
              :model-value="modelValue"
              :filterable="!isMobile"
              :placeholder="placeHolder"
              class="select-input"
              @change="updateValue"
              :popper-class="{'age_teleport': isMobile}"
              @click="alertage"
            >
              <template v-if="isMobile">
              <el-option
                class="vehicle"
                v-for="(item, index) in filtered_ages"
                :key="index"
                :label="item.label"
                :value="item.value"
              />
              </template>
              <template v-if="!isMobile">
              <el-option
                class="vehicle"
                v-for="(item, index) in petage"
                :key="index"
                :label="item.label"
                :value="item.value"
              />
              </template>
            </el-select>
          </el-form-item>
        </div>
      </div>
    </div>
  </div>
</template>

<script lang="ts">
import { computed, defineComponent, ref, onMounted } from "vue";
import { petage } from "@/core/utils";

export default defineComponent({
  props: {
    label: {
      type: String,
      required: true,
      default: "Gender",
    },
    formProp: {
      type: String,
      required: true,
      default: "Select Pet Age",
    },
    modelValue: {
      type: String,
      required: true,
      default: "Select Pet Age",
    },
    placeHolder: {
      type: String,
      required: false,
      default: "",
    },
    tooltipMessage: {
      type: String,
      required: false,
      default: "",
    },
    isRequired: {
      type: Boolean,
      required: false,
      default: false,
    },
    isTooltip: {
      type: Boolean,
      required: false,
      default: false,
    },
  },

  setup(_, context) {


    const updateValue = (event) => {
      search.value = ''
      context.emit("update:modelValue", event);
      context.emit("change", event);
    };

    const search = ref('')

    const filtered_ages = computed(() => {
      if(petage) {
        return petage.filter(obj => 
        obj.label.toString().toLowerCase().includes(search.value.toLowerCase()))
      }
    })

    const isMobile = window.innerWidth < 960 ? true : false

    const age_id = ref('')
    onMounted(() => {
      if(isMobile) {
        const age_teleport = document.getElementsByClassName('age_teleport')[0]
        if(age_teleport)
        age_id.value = age_teleport.id
      }
    })

    const age_ref = ref()

    const alertage = () => {
      // setTimeout(() => {
      //   age_ref.value.focus()
      // }, 500);
    }


    return {
      updateValue,
      alertage,
      petage,
      age_ref,
      filtered_ages,
      search,
      isMobile,
      age_id
    };
  },
});
</script>

<style>
.age_teleport {
    position: relative;
    background-color: #fff;
    top: 45px;
}
</style>
