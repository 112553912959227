import { GetterTree } from 'vuex';

// eslint-disable-next-line import/no-cycle
import { RootState } from '@/store';

import { ThirdParty } from '@/core/types/Quotes';

import { State } from './state';

export type Getters = {
  getThirdParty(state: State): Array<ThirdParty> | null;
}

export const getters: GetterTree<State, RootState> & Getters = {
  getThirdParty: (state) =>  {
    const quotes = state.thirdParty
    console.log(quotes);
    return quotes
  },
};
