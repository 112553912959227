import { App } from 'vue';
import { initComponent } from '../components';
import { dates } from '@/core/functions/dateValues';
import { personalDetails } from '@/core/functions/personalDetails';
import { rule } from '@/core/functions/rulesValues';
import { currency } from '@/core/functions/currency';

import Language from '@/core/services/Language';
import Countries from '@/core/services/Countries';
import PersonalDetails from '@/core/services/PersonalDetails';
import Quotes from '@/core/services/Quotes';
import ApiService from '@/core/services/ApiService';

export function initGlobal (app: App<Element>) {
    initComponent(app);
    
    dates(app);
    currency(app);
    personalDetails(app);
    rule(app);
    
    Language.init(app);
    Countries.init(app);
    PersonalDetails.init(app);
    ApiService.init(app);
    Quotes.init(app);
}