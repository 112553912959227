import { store } from "@/store";

const language = window.localStorage.getItem('language') || 'en' 

class Validations {
    public static country_code = '+961'
    public static customer() {
        return store.state.personals.customer
    }
    
    public static email(rule: any, value: any, callback: any) {
        const re = /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
        if(!value) {
            callback(new Error( language === 'ar' ? 'الرجاء تسجيل بريدك الإلكتروني' : 'Please enter your email'))
        } else if(!re.test(String(value).toLowerCase())) {
            callback(new Error( language === 'ar' ? 'يجب أن يكون عنوان البريد الإلكتروني صالحًا' : 'Email address must be valid'))
        } else {
            callback();
        }
    }

    public static pet_age(rule: any, value: any, callback: any) {
        const re = /^([0-9])$/;
        if(!value) {
            callback(new Error( language === 'ar' ? 'يرجى تحديد عمر حيوانك الأليف' : 'Please select your pet age'))
        } else {
            callback();
        }
    }

    public static pet_type(rule: any, value: any, callback: any) {
        if(!value) {
            callback(new Error( language === 'ar' ? 'يرجى تحديد عمر حيوانك الأليف' : 'Please select Cat / Dog'))
        } else {
            callback();
        }
    }

    public static names(rule: any, value: any, callback: any) {
        if(!value) {
            callback(new Error( language === 'ar' ? 'الرجاء إدخال اسمك بالكامل' : 'Please enter your full name'))
        } else {
            callback();
        }
    }

    public static pet_name(rule: any, value: any, callback: any) {
        if(!value) {
            callback(new Error( language === 'ar' ? 'الرجاء إدخال اسم حيوانك الأليف' : 'Please enter your pet name'))
        } else {
            callback();
        }
    }

    public static phone_number(rule: any, value: any, callback: any) {
        if(!value) {
            callback(new Error( language === 'ar' ? 'الرجاء إدخال رقم الهاتف' : 'Please enter phone number'))
        } 
        
        if (Validations.country_code === '+961' && (value < 999999 || value > 99999999)) {
            callback(new Error( language === 'ar' ? 'يرجى إدخال 7 أرقام على الأقل و8 أرقام كحد أقصى.' : 'Please enter at least 7 digits and max 8 digits.'))
        } else if (Validations.country_code !== '+961' && (value < 9999999 || value > 9999999999)) {
            callback(new Error( language === 'ar' ? 'يرجى إدخال 8 أرقام على الأقل و 10 أرقام كحد أقصى.' : 'Please enter at least 8 digits and max 10 digits.'))
        } else {
            callback();
        }
    }

    public static gender(rule: any, value: any, callback: any) {
        if(!value) {
            callback(new Error( language === 'ar' ? 'الرجاء تحديد جنس الحيوان الأليف' : 'Please select pet gender'))
        } 
        else {
            callback();
        }
    }
};

export default Validations;
