import { ActionTree, ActionContext } from 'vuex';

import { RootState } from '@/store';

import { State } from './state';
import { Mutations } from './mutations';
import { QuoteMutationTypes } from '@/core/enum/Quotes/Mutations';
import { QuoteActionTypes } from '@/core/enum/Quotes/Actions';
import { Links } from '@/core/enum/Links';
import axios from 'axios';
import { isCalled } from '@/store/stateless';

import { ThirdParty } from '@/core/types';

import router from '@/router';


type AugmentedActionContext = {
  commit<K extends keyof Mutations>(
    key: K,
    payload: Parameters<Mutations[K]>[1],
  ): ReturnType<Mutations[K]>;
} & Omit<ActionContext<State, RootState>, 'commit'>

export interface Actions {
  [QuoteActionTypes.GET_QUOTES](
    { commit }: AugmentedActionContext,
    payload:ThirdParty
  );

  [QuoteActionTypes.PROCEED_BUY](
    { commit }: AugmentedActionContext,
    payload:any
  );
  
}

export const actions: ActionTree<State, RootState> & Actions = {
  async [QuoteActionTypes.GET_QUOTES]({ commit },payload) {
    try {
      // if (isCalled.getQuotes) return;
      // // const payload = {
      // //   lang: window.localStorage.getItem('language'),
      // //   isDropdown: true
      // // }
      console.log("in api",payload);
      const data = await axios.post(Links.GET_QUOTES, payload);
      commit(QuoteMutationTypes.SET_QUOTES, data.data.data.quotes);
    }
    catch (err) {
      return err;
    }
  },

  async [QuoteActionTypes.PROCEED_BUY]({ commit },payload) {

    const data = await axios.post(Links.PROCEED_BUY, payload);
    console.log('bnr',data);
    window.localStorage.setItem('interested_policy',JSON.stringify(data.data.data.quotes));
    window.localStorage.setItem('selected_premium',JSON.stringify(data.data.data.selected_premium));
    window.localStorage.setItem('basmah',data.data.data.basmah);
    // commit(QuoteMutationTypes.SET_INTERESTED_POLICY, data.data.data);

  },
};
