export enum Links {
    /**
     * @description GET
     */
    GET_COUNTRIES = '/health/countries',
    GET_COUNTRIES_CODE = '/car/country-codes',
    /**
     * @description POST
     */
    POST_PERSONAL_DETAILS = '/pet/pet-lead',
    GET_CUSTOMER = '/pet/pet-lead',
    
    GET_QUOTES = '/pet/compare-quotes',
    PROCEED_BUY = '/pet/save-insurance',
};
