<template>
    <div class="form-block">
      <div class="label-block">
        <div class="label-block_label">
          <label>{{ label }}<span v-if="isRequired"> *</span> </label>
        </div>
        <div class="label-block_question position-relative" v-if="isTooltip">
          <el-tooltip class="box-item" effect="light">
            <template #content>
              <p class="tooltip-content">{{ tooltipMessage }}</p>
            </template>
            <a
              class="popper"
              data-bs-trigger="hover"
              placement="right"
              data-bs-toggle="popover"
            >
              <div class="icon">
                <span class="icon-question-icon"></span>
              </div>
            </a>
          </el-tooltip>
        </div>
      </div>
  
        <div class="input-block">
            <div class="row">
              <el-form-item :prop="formProp">
              <el-radio-group :model-value="modelValue" >
                <el-radio 
                  v-for="(pet_type, index) in pet_types" 
                  :key="index" 
                  :label="pet_type.value" 
                  size="large" 
                  @click="updateValue(pet_type.value)"
                  border
                  >
                  {{ $t(pet_type.label.toLocaleLowerCase()) }}
                  </el-radio>
              </el-radio-group>
              </el-form-item>
            </div>
        </div>
    </div>
</template>

<script lang="ts">
import { defineComponent, ref } from "vue";

export default defineComponent({
  props: {
    label: {
      type: String,
      required: true,
      default: "Pet Type",
    },
    formProp: {
      type: String,
      required: true,
      default: "Pet Type",
    },
    modelValue: {
      type: Number || null,
      required: true,
      default: null,
    },
    placeHolder: {
      type: String,
      required: false,
      default: "",
    },
    tooltipMessage: {
      type: String,
      required: false,
      default: "",
    },
    isRequired: {
      type: Boolean,
      required: false,
      default: false,
    },
    isTooltip: {
      type: Boolean,
      required: false,
      default: false,
    },
  },

  setup(_, context) {
    const updateValue = (value) => {
      if(value) context.emit("update:modelValue", parseInt(value));
    };

    const pet_types = ref([
      {
        value: 1,
        label: 'Cat'
      },
      {
        value: 2,
        label: 'Dog'
      },
    ])
    return {
      pet_types,
      updateValue,
    };
  },
});
</script>