class BAICurrency{
    public static currencyFormater(val: number, decimalPlace = 0) {
        const formatter = new Intl.NumberFormat('en-US', {
            // style: 'currency',
            // currency: 'USD',
            minimumFractionDigits: decimalPlace
        });
        // console.log(formatter.format(val));
        return formatter.format(val)
    }

}

export default BAICurrency;