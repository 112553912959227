<template>
    <h-header-mobile />
    
    <section class="vechile-detail-section" v-if="thirdParty && thirdParty.length > 0">
        <div class="container">
            <div class="vechile-detail-container">
                <div class="vechile-detail_row">
                    <div class="row justify-content-center">
                        <div class="col-lg-11 col-md-7 form-left-column">
                            <h-title
                            :title="$t('compare_quotes')"
                            :isShow="false"
                            />
                        </div>
                    </div>
                </div>
            </div>
            <div class="vechile-list-container main-container">
                <div class="vechile-list_row">
                    <div class="result-listing" >
                        <div class="result-listing-full-block">
                            <template v-for="(quote, index) in thirdParty" :key="index">
                                <div class="result-listing__item lowest-price position-relative">
                                    <div class="result-listing__item__block overflow-hidden">
                                        <div class="result-listing__item__block-top">
                                            <div class="row g-0">
                                                <div class="col-lg-10 col-md-12 left-col">
                                                    <div class="result-listing__item__block_left h-100">
                                                        <div class="result-listing__item__block_top">
                                                            <div class="result-listing__item__block_top_row d-flex flex-wrap align-items-center">
                                                                <div class="partner-logo">
                                                                    <img
                                                                        :src="`${domain}${url}/img/${quote.image}`"
                                                                        class="img-fluid"
                                                                    />
                                                                </div>
                                                                <div class="partner-content">
                                                                    <div class="partner-content_title">
                                                                        <h2>{{ quote.policy_type }}</h2>
                                                                    </div>
                                                                    <div class="partner-content_bottom d-flex">
                                                                        <div class="partner-content_bottom_item" >
                                                                            <p>{{ $t('financial_limitation') }}
                                                                                <span class="text" v-if="!$filters.isString(quote.own_damage_cover)" dir="ltr">$ {{ $filters.currency(quote.own_damage_cover) || 0 }}</span>
                                                                                <span v-else>{{ quote.own_damage_cover || 0 }}</span>
                                                                            </p>
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </div>
                                                        <div class="result-listing__item__block_medium">
                                                            <div class="row gx-5">
                                                                <div class="col-md-4 col-sm-12 first-col">
                                                                    <div class="listing-ul">
                                                                        <ul>
                                                                            <template v-for="(benefit, bnIndex) in quote.benefits" :key="bnIndex">
                                                                                <template v-if="bnIndex < Math.ceil(quote.benefits.length / 2)">
                                                                                    <li :class="benefit.applicable ? 'check' : 'cross'">
                                                                                        {{ benefit.benefit }}
                                                                                    </li>
                                                                                </template>
                                                                            </template>
                                                                        </ul>
                                                                    </div>
                                                                </div>
                                                                <div class="col-md-4 col-sm-12 second-col">
                                                                    <div class="listing-ul">
                                                                        <ul>
                                                                            <template v-for="(benefit, bnIndex) in quote.benefits" :key="bnIndex">
                                                                                <template v-if="bnIndex >= Math.ceil(quote.benefits.length / 2)">
                                                                                    <li :class="benefit.applicable ? 'check' : 'cross'">
                                                                                        {{ benefit.benefit }}
                                                                                    </li>
                                                                                </template>
                                                                            </template>
                                                                        </ul>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                            <div class="row pt-5 gx-5" v-if="quote.notes != ''">
                                                                <div class="col-sm-12">
                                                                    <p>{{ $t('notes') }} : {{ quote.notes }}</p>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                                <div class="col-lg-2 col-md-12 right-col">
                                                    <div class="right-buy-now text-center">
                                                        <div class="right-buy-now-top">
                                                            <div class="premium-title">
                                                                <p>{{ $t('total_premium') }}</p>
                                                            </div>
                                                            <div class="price-title">
                                                                <h4 class="mb-0" style="direction:ltr;">$ {{ $filters.currency(quote.policy_price) || 0 }}</h4>
                                                            </div>
                                                            <div class="buy-now-button">
                                                                <el-button
                                                                    :disabled="quote.policy_price <= 0"
                                                                    @click.prevent="BuyNow(quote.id)"
                                                                    class="btn buy-now"
                                                                    >{{ $t("buy_now") }}</el-button
                                                                >
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <!-- end -->
                            </template>

                        </div>
                    </div>
                </div>
            </div>
        </div>
    </section>

    <template v-if="thirdParty && thirdParty.length == 0">
        <section class="vechile-list page-content">
            <div class="container">
                <div class="vechile-list-container main-container">
                    <div class="vechile-list_row">
                        <div class="result-listing-full-block">
                            <div class="text-center no-result-block">
                                <h2>{{ $t('thank_you') }}</h2>
                                <p>
                                    {{ $t('callback_thank_you_msg') }}
                                </p>
                                <hr>
                                <p> 
                                    <span v-html="$t('no_quotes_manual_quote_title')"></span>
                                    <a href="https://api.whatsapp.com/send?phone=96171396654" target="_blank">
                                        <img height="25" width="25" src="@/assets/images/icons/whatsapp.svg"></a>
                                    <br />
                                    <a :href="`${goBakUrl}/${language}`">{{ $t('go_back_home') }}</a>
                                </p>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </section>
    </template>
</template>


<script lang="ts">

import { store } from "@/store";
import { currency } from "@/core/functions/currency";
import Quotes from "@/core/services/Quotes";
import router from "@/router";
import axios from 'axios';
import { Links } from '@/core/enum/Links';
import { ElLoading } from "element-plus";
import { defineComponent, onMounted, computed, ref, toRef } from "vue";

export default defineComponent({
    setup(_) {
        const domain = ref(process.env.VUE_APP_ASSETS_DOMAIN)
        const url = ref(process.env.VUE_APP_INSURANCE_ASSETS_URL)
        const goBakUrl = process.env.VUE_APP_PAYMENT_GATEWAY_URL

        let thirdParty = computed(() => {
            return store.state.quotes.thirdParty
        });

        const language = computed(() => {
            return window.localStorage.getItem('language');
        })

        onMounted(async () => {
            const lead_id: any = window.localStorage.getItem('lead_id');
            
            const loading = ElLoading.service({
                lock: true,
                text: 'Loading',
                background: 'rgba(0, 0, 0, 0.9)',
            })
            const payload = {
                language: window.localStorage.getItem('language'),
                lead_id: window.localStorage.getItem('lead_id')
            }
            await Quotes.getQuotes(payload);
            loading.close()
        })
        
        const BuyNow = async (insurance_id) => {
            const loading = ElLoading.service({
                lock: true,
                text: 'Loading',
                background: 'rgba(0, 0, 0, 0.9)',
            })
            const lead_id: any = window.localStorage.getItem('lead_id');
            const payload = {
                pet_lead_id: lead_id,
                insurance_policy_master_id: insurance_id,
            }
            loading.close()
            const data = await axios.post(Links.PROCEED_BUY, payload);
            const route = router.resolve({ name: 'ThankYou'})
            window.location.href = route.href;
        }

        return {
            thirdParty,
            domain,
            url,
            language,
            goBakUrl,
            BuyNow
        }
    }
});
</script>