import { resolveComponent as _resolveComponent, createVNode as _createVNode, createElementVNode as _createElementVNode, withCtx as _withCtx, openBlock as _openBlock, createBlock as _createBlock } from "vue"

const _hoisted_1 = { class: "vechile-details-list" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_h_input = _resolveComponent("h-input")!
  const _component_h_pet_type = _resolveComponent("h-pet-type")!
  const _component_h_gender = _resolveComponent("h-gender")!
  const _component_h_age = _resolveComponent("h-age")!
  const _component_h_proceed = _resolveComponent("h-proceed")!
  const _component_el_form = _resolveComponent("el-form")!

  return (_openBlock(), _createBlock(_component_el_form, {
    ref: "formPersonalDetails",
    model: _ctx.customer,
    "status-icon": "",
    rules: _ctx.rules,
    class: "demo-ruleForm"
  }, {
    default: _withCtx(() => [
      _createElementVNode("div", _hoisted_1, [
        _createVNode(_component_h_input, {
          isRequired: "",
          label: _ctx.$t('pet_name'),
          isType: "text",
          formProp: "leads.pet_name",
          modelValue: _ctx.customer.leads.pet_name,
          "onUpdate:modelValue": _cache[0] || (_cache[0] = ($event: any) => ((_ctx.customer.leads.pet_name) = $event)),
          placeHolder: _ctx.$t('pet_name_placeholder'),
          maxLength: "62",
          onkeydown: _ctx.language === 'en' ? `return /[a-zA-Z\\s'\\- ]/i.test(event.key)` : ''
        }, null, 8, ["label", "modelValue", "placeHolder", "onkeydown"]),
        _createVNode(_component_h_pet_type, {
          isRequired: "",
          label: _ctx.$t('pet_type'),
          formProp: "leads.pet_type",
          modelValue: _ctx.customer.leads.pet_type,
          "onUpdate:modelValue": _cache[1] || (_cache[1] = ($event: any) => ((_ctx.customer.leads.pet_type) = $event))
        }, null, 8, ["label", "modelValue"]),
        _createVNode(_component_h_gender, {
          isRequired: "",
          label: _ctx.$t('pet_gender'),
          isType: "select",
          formProp: "leads.pet_gender",
          modelValue: _ctx.customer.leads.pet_gender,
          "onUpdate:modelValue": _cache[2] || (_cache[2] = ($event: any) => ((_ctx.customer.leads.pet_gender) = $event))
        }, null, 8, ["label", "modelValue"]),
        _createVNode(_component_h_age, {
          isRequired: "",
          label: _ctx.$t('pet_age'),
          isType: "text",
          formProp: "leads.pet_age",
          modelValue: _ctx.customer.leads.pet_age,
          "onUpdate:modelValue": _cache[3] || (_cache[3] = ($event: any) => ((_ctx.customer.leads.pet_age) = $event)),
          isTooltip: "",
          tooltipMessage: _ctx.$t('pet_age_tooltip')
        }, null, 8, ["label", "modelValue", "tooltipMessage"]),
        _createVNode(_component_h_input, {
          isRequired: "",
          label: _ctx.$t('full_name'),
          isType: "text",
          formProp: "customer_name",
          modelValue: _ctx.customer.customer_name,
          "onUpdate:modelValue": _cache[4] || (_cache[4] = ($event: any) => ((_ctx.customer.customer_name) = $event)),
          placeHolder: _ctx.$t('full_name_placeholder'),
          maxLength: "62",
          onkeydown: _ctx.language === 'en' ? `return /[a-zA-Z\\s'\\- ]/i.test(event.key)` : ''
        }, null, 8, ["label", "modelValue", "placeHolder", "onkeydown"]),
        _createVNode(_component_h_input, {
          isRequired: "",
          label: _ctx.$t('phone_number'),
          isType: "phone",
          formProp: "customer_phone",
          maxLength: _ctx.customer.phone_country_code == '+961' ? '8' : '10',
          placeHolder: _ctx.$t('phone_number_placeholder'),
          modelValue: _ctx.customer.customer_phone,
          "onUpdate:modelValue": _cache[5] || (_cache[5] = ($event: any) => ((_ctx.customer.customer_phone) = $event)),
          countryCode: _ctx.customer.phone_country_code,
          "onUpdate:countryCode": _cache[6] || (_cache[6] = ($event: any) => ((_ctx.customer.phone_country_code) = $event))
        }, null, 8, ["label", "maxLength", "placeHolder", "modelValue", "countryCode"]),
        _createVNode(_component_h_input, {
          isRequired: "",
          label: _ctx.$t('email_address'),
          isType: "text",
          formProp: "customer_email",
          placeHolder: _ctx.$t('email_address_placeholder'),
          modelValue: _ctx.customer.customer_email,
          "onUpdate:modelValue": _cache[7] || (_cache[7] = ($event: any) => ((_ctx.customer.customer_email) = $event)),
          maxLength: "62"
        }, null, 8, ["label", "placeHolder", "modelValue"]),
        _createVNode(_component_h_proceed, {
          onHandleClick: _cache[8] || (_cache[8] = ($event: any) => (_ctx.personalDetails.click(_ctx.formPersonalDetails))),
          fullscreenLoading: _ctx.loading
        }, null, 8, ["fullscreenLoading"])
      ])
    ]),
    _: 1
  }, 8, ["model", "rules"]))
}