<template>
  <el-form
    ref="formPersonalDetails"
    :model="customer"
    status-icon
    :rules="rules"
    class="demo-ruleForm"
  >
    <div class="vechile-details-list">
      <h-input
        isRequired
        :label="$t('pet_name')"
        isType="text"
        formProp="leads.pet_name"
        v-model="customer.leads.pet_name"
        :placeHolder="$t('pet_name_placeholder')"
        maxLength="62"
        :onkeydown="language === 'en' ? `return /[a-zA-Z\\s'\\- ]/i.test(event.key)` : ''"
      />
      <h-pet-type
        isRequired
        :label="$t('pet_type')"
        formProp="leads.pet_type"
        v-model="customer.leads.pet_type">
      </h-pet-type>
      <h-gender
        isRequired
        :label="$t('pet_gender')"
        isType="select"
        formProp="leads.pet_gender"
        v-model="customer.leads.pet_gender"
      />
      <h-age
        isRequired
        :label="$t('pet_age')"
        isType="text"
        formProp="leads.pet_age"
        v-model="customer.leads.pet_age"
        isTooltip
        :tooltipMessage="$t('pet_age_tooltip')"
      />
      <h-input
        isRequired
        :label="$t('full_name')"
        isType="text"
        formProp="customer_name"
        v-model="customer.customer_name"
        :placeHolder="$t('full_name_placeholder')"
        maxLength="62"
        :onkeydown="language === 'en' ? `return /[a-zA-Z\\s'\\- ]/i.test(event.key)` : ''"
      />
      <h-input
        isRequired
        :label="$t('phone_number')"
        isType="phone"
        formProp="customer_phone"
        :maxLength="customer.phone_country_code == '+961' ? '8' : '10'"
        :placeHolder="$t('phone_number_placeholder')"
        v-model:modelValue="customer.customer_phone"
        v-model:countryCode="customer.phone_country_code"
      />
      <h-input
        isRequired
        :label="$t('email_address')"
        isType="text"
        formProp="customer_email"
        :placeHolder="$t('email_address_placeholder')"
        v-model="customer.customer_email"
        maxLength="62"
      />
      <h-proceed @handleClick="personalDetails.click(formPersonalDetails)" :fullscreenLoading="loading" />
    </div>
  </el-form>
</template>

<script lang="ts">
import { computed, defineComponent, onMounted, reactive, ref } from "vue";
import { formPersonalDetails } from "@/store/stateless";
import { emirates } from "@/core/utils";
import { store } from "@/store";
import Validations from "@/core/validations";
import router from '@/router';
import personalDetails from "@/core/services/PersonalDetails";
import moment from 'moment'

export default defineComponent({
  setup(_) {
    const customer = computed(() => {
      return store.state.personals.customer;
    });

    const loading = computed(() => {
      return store.state.personals.loading
    })

    const language = computed(() => {
      return window.localStorage.getItem('language');
    })
    onMounted(async() => {
      // customer.value.customer_source_url = router.currentRoute.value.fullPath;
      // customer.value.btm_source = router.currentRoute.value.query && router.currentRoute.value.query.btm_source ?  router.currentRoute.value.query.btm_source.toString() : '';
      customer.value.utm_source = router.currentRoute.value.query?.utm_source ?  router.currentRoute.value.query.utm_source.toString() : 'website';
      customer.value.utm_medium = router.currentRoute.value.query?.utm_medium ?  router.currentRoute.value.query.utm_medium.toString() : 'website';
      customer.value.utm_campaign = router.currentRoute.value.query?.utm_campaign ?  router.currentRoute.value.query.utm_campaign.toString() : 'nc';
      customer.value.utm_content = router.currentRoute.value.query?.utm_content ?  router.currentRoute.value.query.utm_content.toString() : 'website';
      
      customer.value.leads.utm_source = router.currentRoute.value.query?.utm_source ?  router.currentRoute.value.query.utm_source.toString() : 'website';
      customer.value.leads.utm_medium = router.currentRoute.value.query?.utm_medium ?  router.currentRoute.value.query.utm_medium.toString() : 'website';
      customer.value.leads.utm_campaign = router.currentRoute.value.query?.utm_campaign ?  router.currentRoute.value.query.utm_campaign.toString() : 'nc';
      customer.value.leads.utm_content = router.currentRoute.value.query?.utm_content ?  router.currentRoute.value.query.utm_content.toString() : 'website';
      const uuid = router.currentRoute.value.query?.uuid ? router.currentRoute.value.query.uuid : window.localStorage.getItem("uuid");
      if(uuid) {
        customer.value.uuid = router.currentRoute.value.query?.uuid ? router.currentRoute.value.query.uuid.toString() : window.localStorage.getItem("uuid");
        const result = await personalDetails.getCustomerDetails(customer.value)
      }  
    })
    
    const rules = reactive({
      customer_name: [{ validator: Validations.names, trigger: ["blur", "change"] }],
      customer_phone: [{ validator: Validations.phone_number, trigger: ["blur", "change"] }],
      customer_email: [{ validator: Validations.email, trigger: ["blur", "change"] }],
      'leads.pet_name': [{ validator: Validations.pet_name, trigger: ["blur", "change"] }],
      'leads.pet_age': [{ validator: Validations.pet_age, trigger: ["blur", "change"] }],
      'leads.pet_type': [{ validator: Validations.pet_type, trigger: ["blur", "change"] }],
      'leads.pet_gender': [{ validator: Validations.gender, trigger: ["blur", "change"] }],
    });

    return {
      loading,
      formPersonalDetails,
      emirates,
      customer,
      language,
      rules
    };
  },
});
</script>
