import { App } from "vue";
import { FormInstance } from "element-plus";
import { Member } from "@/core/types";
import pd from "@/core/services/PersonalDetails";
import { store } from "@/store";

export const personalDetails = (app: App<Element>) => {
    const dates = app.config.globalProperties.dates;
    app.config.globalProperties.personalDetails = {
        async click(formEl: FormInstance) {
            if (!formEl) return;
            formEl.validate(async (valid, fields: any) => {
                if (valid) {
                    pd.setLoading(true);
                    const customer = store.state.personals.customer
                    await pd.post(customer);
                    pd.setLoading(false);
                } else {
                    this.scrollToError(Object.keys(fields)[0]);
                }
            })
        },

        reset(formEl: FormInstance | undefined) {
            if (!formEl) return
            formEl.clearValidate()
        },

        addNewMember(formEl: FormInstance, leads: Array<Member>, member: Member) {
            if (member.pet_name && member.pet_age && member.pet_gender && member.pet_type) {
                if (!formEl) return;
                formEl.validate(async (valid) => {
                    if (valid) {
                        member.is_editable = false;
                        leads.push({
                            id: null,
                            customer_id: 0,
                            pet_age: null,
                            insurance_policy: 0,
                            pet_name: null,
                            pet_type: null,
                            pet_gender: null,
                            lead_source: null,
                            duplicate_source: null,
                            other_source: null,
                            utm_source: null,
                            utm_medium: null,
                            utm_campaign: null,
                            utm_content: null,
                            other_utm_source: null,
                            other_utm_medium: null,
                            other_utm_campaign: null,
                            other_utm_content: null,
                            is_editable: true
                        })
                    }
                })
            }
        },

        deleteMember(leads: Array<Member>, index: number) {
            leads.splice(index, 1);
        },

        editMember(leads: Array<Member>, member: Member) {
            leads.map(x => {
                x.is_editable = false;
            });

            member.is_editable = true;

            leads.map(x => {
                if (!x.pet_name || !x.pet_age || !x.pet_gender) {
                    this.deleteMember(leads, leads.length - 1);
                }
            })
        },

        scrollToError(e:string) {
            if(e == 'leads.pet_age') {
                setTimeout(() => {
                    window.scroll(0, 450)
                }, 500);
            } 
            else if(e == 'customer_name') {
                setTimeout(() => {
                    window.scroll(0, 550)
                }, 500);
            } 
            else if(e == 'customer_phone') {
                setTimeout(() => {
                    window.scroll(0, 650)
                }, 500);
            } 
            else if(e == 'customer_email') {
                setTimeout(() => {
                    window.scroll(0, 700)
                }, 500);
            } 
            else {
                setTimeout(() => {
                    window.scroll(0, 0)
                }, 500);
            }
        }
    }
}