import Quotes from '@/core/services/Quotes';
import { ThirdParty } from '@/core/types/Quotes';


export type State = {
  thirdParty: Array<ThirdParty> | null;
  loading: Boolean,
  isDisable: Boolean | null,
  isLoading: Boolean,
  hasData: Boolean
}



export const state: State = {
  thirdParty: [{
    id: 0,
    provider_name: '',
    lang: window.localStorage.getItem('language') || '',
    terms_url: '',
    image: '',
    policy_name: '',
    policy_type: 0,
    car_insurance_id: 0,
    policy_price: 0,
    third_party_limit: '',
    notes: '',
    sort_order: ''
  }] as Array<ThirdParty>,
  loading: false,
  isDisable: false,
  isLoading: false,
  hasData: true
};