const language = window.localStorage.getItem('language');

export const petage = [
    { value: "0", label: language === 'ar' ? '3 إلى 11 شهرًا' : "3 to 11 months" },
    { value: "1", label: language === 'ar' ? '1 سنة' : "1 year" },
    { value: "2", label: language === 'ar' ? '2 سنة' : "2 year" },
    { value: "3", label: language === 'ar' ? '3 سنة' : "3 year" },
    { value: "4", label: language === 'ar' ? '4 سنة' : "4 year" },
    { value: "5", label: language === 'ar' ? '5 سنة' : "5 year" },
    { value: "6", label: language === 'ar' ? '6 سنة' : "6 year" },
    { value: "7", label: language === 'ar' ? '7 سنة' : "7 year" },
    { value: "8", label: language === 'ar' ? '8 سنة' : "8 year" },
    { value: "9", label: language === 'ar' ? '9 سنة' : "9 year" },
    { value: "10", label: language === 'ar' ? '10+ سنة' : "10+ year" },
    // { value: "11", label: language === 'ar' ? '11 سنة' : "11 year" },
    // { value: "12", label: language === 'ar' ? '12 سنة' : "12 year" },
    // { value: "13", label: language === 'ar' ? '13 سنة' : "13 year" },
    // { value: "14", label: language === 'ar' ? '14 سنة' : "14 year" },
    // { value: "15", label: language === 'ar' ? '15 سنة' : "15 year" },
]
