<template>
<footer>
    <div class="mobile-footer d-md-none">
            <div class="container">
                <div class="footer-top-list_row">
                    <div class="row gx-6">
                        <div class="col-md-4 get-insured">
                            <div class="footer-top-list_block h-100 d-flex flex-column justify-content-between">
                                <div class="footer-top-list_block_item">
                                    <h6>Get Insured<span class="icon-dropdown-down" style="display: none;"></span><span
                                            class="icon-dropdown-down" style="display: none;"></span><span
                                            class="icon-dropdown-down" style="display: none;"></span><span
                                            class="icon-dropdown-down" style="display: none;"></span><span
                                            class="icon-dropdown-down"></span></h6>
                                    <ul>
                                        <li><a href="#">Car Insurance</a></li>
                                        <li><a href="#">Health Insurance</a></li>
                                        <li><a href="#">Home Insurance</a></li>
                                        <li><a href="#">Bike Insurance</a></li>
                                        <li><a href="#">Other Insurance</a></li>
                                        <li><a href="#">Renew a policy</a></li>
                                        <!-- <li><a href="#">Yacht Insurance</a></li>
                                                                <li><a href="#">Jet Ski Insurance</a></li>
                                                                <li><a href="#">Corporates Insurance</a></li> -->
                                    </ul>
                                </div>
                                <div class="footer-top-list_block_item">
                                    <h6>Support<span class="icon-dropdown-down" style="display: none;"></span><span
                                            class="icon-dropdown-down" style="display: none;"></span><span
                                            class="icon-dropdown-down" style="display: none;"></span><span
                                            class="icon-dropdown-down" style="display: none;"></span><span
                                            class="icon-dropdown-down"></span></h6>
                                    <ul>
                                        <li><a href="#">Help center </a></li>
                                        <li><a href="#"> Contact</a></li>
                                        <li><a href="#"> Cancellation</a></li>
                                    </ul>
                                </div>
                            </div>
                        </div>
                        <div class="col-md-4 company">
                            <div class="footer-top-list_block h-100 d-flex flex-column justify-content-between">
                                <div class="footer-top-list_block_item">
                                    <h6>Company<span class="icon-dropdown-down" style="display: none;"></span><span
                                            class="icon-dropdown-down" style="display: none;"></span><span
                                            class="icon-dropdown-down" style="display: none;"></span><span
                                            class="icon-dropdown-down" style="display: none;"></span><span
                                            class="icon-dropdown-down"></span></h6>
                                    <ul>
                                        <li><a href="#">About </a></li>
                                        <li><a href="#">Insurance Partners</a></li>
                                        <li><a href="#">HR Solutions</a></li>
                                        <li><a href="#">Blog</a></li>
                                        <!-- <li><a href="#"> Packages</a></li> -->
                                    </ul>
                                </div>
                                <div class="footer-top-list_block_item">
                                    <h6>Calculators<span class="icon-dropdown-down" style="display: none;"></span><span
                                            class="icon-dropdown-down" style="display: none;"></span><span
                                            class="icon-dropdown-down" style="display: none;"></span><span
                                            class="icon-dropdown-down" style="display: none;"></span><span
                                            class="icon-dropdown-down"></span></h6>
                                    <ul>
                                        <li><a href="#">Car Loan </a></li>
                                        <li><a href="#"> Mortgage</a></li>
                                        <li><a href="#"> Insurance Calc</a></li>
                                    </ul>
                                </div>
                            </div>
                        </div>
                        <div class="col-md-4 quick-links-col">
                            <div class="footer-top-list_block h-100 d-flex flex-column justify-content-between quick-links">
                                <div class="footer-top-list_block_item">
                                    <h6>Quick links<span class="icon-dropdown-down" style="display: none;"></span><span
                                            class="icon-dropdown-down" style="display: none;"></span><span
                                            class="icon-dropdown-down" style="display: none;"></span><span
                                            class="icon-dropdown-down" style="display: none;"></span><span
                                            class="icon-dropdown-down"></span></h6>
                                    <ul>
                                        <li><a href="#">Privacy Page</a></li>
                                        <li><a href="#">Terms &amp; Conditions</a></li>
                                        <li><a href="#">Al Manarah Insurance</a></li>
                                        <li><a href="#">E- Darat </a></li>
                                        <!-- <li><a href="#">Renew a Policy</a></li> -->
                                    </ul>
                                </div>

                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <div id="bai-footer" class="footer text-white">
            <div class="container">
                <div class="footer-top">
                    <div class="footer-top_row">
                        <div class="row justify-content-between">
                            <div class="col-md-6 col-lg-4 footer-col-first">
                                <div class="footer-top_row_item">
                                    <div class="need-assitance">
                                        <div class="need-assitance_title text-uppercase">
                                            <h5 class="mb-0">{{ $t("need_assistance") }}</h5>
                                        </div>
                                        <div class="need-assitance_whatsapp d-flex flex-wrap align-items-center">
                                            <div class="icon">
                                                <a href="https://api.whatsapp.com/send?phone=96171396654" target="_blank"><img
                                                        src="@/assets/images/icons/whatsapp.svg"
                                                        class="img-fluid"></a>
                                            </div>
                                            <div class="content" style="direction: ltr">
                                                <a href="tel:01488277">{{ $t("call_us") }}
                                                    <span class="d-block">01 488 277</span></a>
                                            </div>
                                        </div>
                                        <div class="custom-button">
                                            <a :href="`${websiteUrl}/${language}/help-center`" class="btn">{{ $t("support_center") }}</a>
                                        </div>
                                    </div>
                                    <div class="contact-list contact-list-wrapper">
                                        <div class="contact-list_item">
                                            <div class="contact-list_item_title text-uppercase">
                                                <p>{{ $t("mail") }}</p>
                                            </div>
                                            <div class="contact-list_item_content">
                                                <p><a
                                                        :href="`mailto:${email}`">{{ email }}</a>
                                                </p>
                                            </div>
                                        </div>
                                        <div class="contact-list_item">
                                            <div class="contact-list_item_title text-uppercase">
                                                <p>{{ $t("head_office") }}</p>
                                            </div>
                                            <div class="contact-list_item_content">
                                                <p>{{ $t("address") }}<a href="https://maps.app.goo.gl/wqW3mx2GwcchCRbF8"
                                                        target="_blank"> - <u>{{ $t("google_map") }}</u></a>
                                                </p>
                                            </div>
                                        </div>
                                        <div class="corporate-block">
                                            <div class="d-flex align-items-center justify-content-between">
                                                <h4><a :href="`${websiteUrl}/${language}/corporate-solutions`">{{ $t("corporate") }} <br /> {{ $t("solution") }}</a></h4>
                                                <div class="corporate-logos">
                                                    <img
                                                        :src="`${websiteUrl}/images/chedid-insurance.png`"
                                                            alt="Chedid Insurance Brokers">
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div class="col-lg-7 footer-list d-flex flex-column justify-content-between footer-right">
                                <div class="footer-top-list_row">
                                    <div class="row gx-6">
                                        <div class="col-md-4 get-insured">
                                            <div
                                                class="footer-top-list_block h-100 d-flex flex-column justify-content-between">
                                                <div class="footer-top-list_block_item">
                                                    <h6>{{ $t("get_insured") }}</h6>
                                                    <ul>
                                                        <li><a :href="`${websiteUrl}/${language}/car-insurance`"> {{ $t("car_insurance") }} </a></li>
                                                        <li><a :href="`${websiteUrl}/${language}/pet-insurance`">{{ $t("pet_insurance") }}</a></li>
                                                        <li><a :href="`${websiteUrl}/${language}/bike-insurance-lebanon`">{{ $t("bike_insurance") }}</a></li>
                                                        <li><a :href="`${websiteUrl}/${language}/travel-insurance`">{{ $t("travel_insurance") }}</a></li>
                                                        <li><a :href="`${websiteUrl}/${language}/expat-health-insurance`">{{ $t("expat_insurance") }}</a></li>
                                                        <li><a :href="`${websiteUrl}/${language}/health-insurance`">{{ $t("health_insurance") }}</a></li>
                                                        <!-- <li><a :href="`${websiteUrl}/${language}/other-insurance`">{{ $t("other_insurance") }}</a></li>
                                                        <li><a :href="`${websiteUrl}/${language}/car-insurance-renewal`">{{ $t("renew_a_policy") }}</a></li> -->
                                                    </ul>
                                                </div>
                                                <div class="footer-top-list_block_item">
                                                    <h6>{{ $t("support") }}</h6>
                                                    <ul>
                                                        <li><a :href="`${websiteUrl}/${language}/help-center`">{{ $t("help_center") }}</a></li>
                                                        <li><a :href="`${websiteUrl}/${language}/contact-us`"> {{ $t("contact") }}</a>
                                                        </li>
                                                        <!-- <li><a :href="`${websiteUrl}/${language}/cancellation`"> {{ $t("cancellation") }}</a></li> -->
                                                        <li><a :href="`${websiteUrl}/${language}/faqs`"> {{ $t("faqs") }}</a></li>
                                                    </ul>
                                                </div>
                                            </div>
                                        </div>
                                        <div class="col-md-4 company">
                                            <div
                                                class="footer-top-list_block h-100 d-flex flex-column justify-content-between">
                                                <div class="footer-top-list_block_item">
                                                    <h6>{{ $t("company") }}</h6>
                                                    <ul>
                                                        <li><a :href="`${websiteUrl}/${language}/about-us`">{{ $t("about") }} </a>
                                                        </li>
                                                        <li><a :href="`${websiteUrl}/${language}/insurance-partners`">{{ $t("insurance_partners") }}</a></li>
                                                        <!-- <li><a :href="`${websiteUrl}/${language}/hr-solutions`">{{ $t("hr_solutions") }}</a></li> -->
                                                        <li><a :href="`${websiteUrl}/blog/${language == 'ar' ? language : ''}`">{{ $t("blog") }}</a></li>
                                                    </ul>
                                                </div>
                                                <div class="footer-top-list_block_item">
                                                    <!-- <h6>{{ $t("calculator") }}</h6>
                                                    <ul>
                                                        <li><a href="#">{{ $t("car_loan") }} </a></li>
                                                        <li><a href="#"> {{ $t("mortgage") }}</a></li>
                                                        <li><a href="#"> {{ $t("insurance_calc") }}</a></li>
                                                    </ul> -->
                                                </div>
                                            </div>
                                        </div>
                                        <div class="col-md-4 quick-links-col">
                                            <div
                                                class="footer-top-list_block h-100 d-flex flex-column justify-content-between quick-links">
                                                <div class="footer-top-list_block_item">
                                                    <h6>{{ $t("quick_links") }}</h6>
                                                    <ul>
                                                        <!-- :href="`${process.env.VUE_APP_PAYMENT_GATEWAY_URL}/e-darat`" -->
                                                        <li><a
                                                                :href="`${websiteUrl}/${language}/privacy-policy`">{{ $t("privacy_page") }}</a></li>
                                                        <li><a
                                                                :href="`${websiteUrl}/${language}/terms-and-conditions`">{{ $t("tac") }}</a></li>
                                                        <!-- <li><a :href="`${websiteUrl}/${language}/al-manarah-insurance`">{{ $t("ami") }}</a></li> -->
                                                        <li><a :href="`${websiteUrl}/${language}/corporate-solutions`">{{ $t("corporate_solution") }}</a></li>
                                                    </ul>
                                                </div>
                                                <div class="social-icons footer-top-list_block_item d-flex flex-wrap">
                                                    <ul class="social-icons-list">
                                                        <li>
                                                            <a :href="`${linkedin}`" target="_blank">
                                                                <i class="icon-linkedin"></i>
                                                            </a>
                                                        </li>
                                                        <li>
                                                            <a :href="`${facebook}`" target="_blank">
                                                                <span class="icon-fb"></span>
                                                            </a>
                                                        </li>
                                                        <li>
                                                            <a :href="`${instagram}`" target="_blank">
                                                                <span class="icon-instagram"></span>
                                                            </a>
                                                        </li>
                                                        <li>
                                                            <a :href="`${twitter}`" target="_blank">
                                                                <span class="icon-twitter"></span>
                                                            </a>
                                                        </li>
                                                    </ul>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <ul class="nav d-flex justify-content-end align-items-center">
                                    <li class="nav-item">
                                        <img src="@/assets/images/icons/visa.svg" alt="Visa"
                                            class="img-fluid">
                                    </li>
                                    <li class="nav-item">
                                        <img src="@/assets/images/icons/master-card.svg"
                                            class="img-fluid" alt="Master Card">
                                    </li>
                                </ul>
                            </div>
                        </div>
                    </div>
                </div>
                <!-- <div class="footer-middle">
                    <div class="contact-list">
                        <div class="contact-list_item">
                            <div class="contact-list_item_title text-uppercase">
                                <p>{{ $t("disclaimers") }}</p>
                            </div>
                            <div class="contact-list_item_content disclaimer">
                                <p>{{ $t('disclaimer_text')}}
                                </p>
                            </div>
                        </div>
                    </div>
                </div> -->
                <div class="footer-bottom">
                    <div class="row align-items-center row-cols-md-2 row-cols-1">
                        <div class="col-lg-4 col-md-12">
                            <img v-if="language ===  'ar'" src="@/assets/images/arlogo-white.svg" class="img-fluid footer-bottom-logo">
                            <img v-else src="@/assets/images/footer-logo.svg" class="img-fluid footer-bottom-logo">
                        </div>
                        <div class="col-lg-4 col-md-12">
                            <div class="copyright text-center">
                            <p>{{ $t("company_of") }} <a href="https://chedid-capital.com/" target="_blank">Chedid Capital</a></p>
                            </div>
                        </div>
                        <div class="col-lg-4 col-md-12">
                            <div class="copyright text-md-end">
                                {{ $t('copyrights_footer').replace('DATE_FOOTER', new Date().getFullYear()) }}
                                <!-- <p>{{ $t("copyrights") }} © {{ new Date().getFullYear() }} BuyAnyInsurance.com</p> -->
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </footer>
</template>

<script lang="ts">
import { computed, defineComponent } from 'vue'

export default defineComponent({
    setup() {
        const websiteUrl = computed(() => {
            return process.env.VUE_APP_PAYMENT_GATEWAY_URL
        })

        const email = computed(() => {
            return process.env.VUE_APP_MAIL
        })

        const language = computed(() => {
            return window.localStorage.getItem('language');
        })

        const linkedin = computed(() => {
            return process.env.VUE_APP_LINKEDIN
        })

        const facebook = computed(() => {
            return process.env.VUE_APP_FACEBOOOK
        })

        const instagram = computed(() => {
            return process.env.VUE_APP_INSTAGRAM
        })

        const twitter = computed(() => {
            return process.env.VUE_APP_TWITTER
        })

        return {
            language,
            linkedin,
            facebook,
            instagram,
            twitter,
            email,
            websiteUrl
        }
    },
})
</script>


<style lang="scss" scoped>
 .footer {
     a{
         color: white;
     }
 }
</style>