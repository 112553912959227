import { Health, Result, Member } from '@/core/types';

export type State = {
  customer: Health;
  result: Result;
  members: Member;
  loading: Boolean;
}

export const state: State = {
  customer: {
    id: null,
    lang: window.localStorage.getItem('language'),
    customer_name: null,
    customer_email: null,
    customer_phone: null,
    phone_country_code: "+961",
    insurance_type: 6,
    customer_source: null,
    utm_source: null,
    utm_medium: null,
    utm_campaign: null,
    utm_content: null,
    uuid: null,
    leads: {
      id: null,
      customer_id:null,
      pet_name:null,
      pet_type:null,
      pet_gender:null,
      pet_age:null,
      insurance_policy:0,
      lead_source:null,
      duplicate_source:null,
      other_source:null,
      utm_source:null,
      utm_medium:null,
      utm_campaign:null,
      utm_content:null,
      other_utm_source:null,
      other_utm_medium:null,
      other_utm_campaign:null,
      other_utm_content:null,
      is_editable: true
    }
  } as Health,

  result: {} as Result,
  members: {} as Member,
  loading: false,
};