import { App } from "vue";
import * as ElementPlusIconsVue from '@element-plus/icons-vue';

/**
 * @description skeleton
 */
import HeaderView from '@/components/skeleton/HeaderView.vue';
import FooterView from '@/components/skeleton/FooterView.vue';
import headerDef from '@/components/skeleton/headers/DefNav.vue';
import headerDefMobile from '@/components/skeleton/headers/DefNavMobile.vue';

/**
 * @description reusables
 */
import ResTitle from '@/components/reusables/Title.vue';
import inputText from '@/components/reusables/InputText.vue';
import inputCheckBox from '@/components/reusables/InputCheckBox.vue';
import selectDate from '@/components/reusables/SelectDate.vue';
import SelectAge from '@/components/reusables/SelectAge.vue';
import SelectPetType from '@/components/reusables/SelectPetType.vue';
import selectGender from '@/components/reusables/SelectGender.vue';
import buttonProceed from '@/components/reusables/ButtonProceed.vue';
import SelectDateV2 from '@/components/reusables/SelectDateV2.vue';

import QuoteDetails from '@/components/forms/QuoteDetails.vue';

/**
 * @description views
 */
 import PersonalDetails from '@/components/forms/PersonalDetails.vue';

export function initComponent(app: App<Element>) {

    const data = [
        { key: 'h-header', component: HeaderView },
        { key: 'h-footer', component: FooterView },
        { key: 'h-header-default', component: headerDef },
        { key: 'h-header-mobile', component: headerDefMobile },
        { key: 'h-title', component: ResTitle },
        { key: 'h-input', component: inputText },
        { key: 'h-checkbox', component: inputCheckBox },
        { key: 'h-date', component: selectDate },
        { key: 'h-age', component: SelectAge },
        { key: 'h-pet-type', component: SelectPetType },
        { key: 'h-gender', component: selectGender },
        { key: 'h-proceed', component: buttonProceed },
        { key: 'h-date-picker-v2', component: SelectDateV2 },
        { key: 'h-personal-details', component: PersonalDetails },
        { key: 'h-quote-details', component: QuoteDetails },
    ];

    data.forEach(element => {
        app.component(element.key, element.component);
    });

    for (const [key, component] of Object.entries(ElementPlusIconsVue)) {
        app.component(key, component)
    }
}
